<template>  
  <v-app class="background-container">             
    <v-main>      
    <div class="first-page">      
      <v-img :src="require('./assets/flowy.jpg')" width="auto" height="auto" style="display:none"/>
    </div>    
      <!-- <v-parallax src="./assets/flowy.jpg">-->
        <v-card class="d-flex flex-column align-center justify-center" height="100vh" style="background-color: transparent;">             
          <div data-aos="fade" data-aos-easing="ease-in-out" data-aos-once="true" data-aos-duration="1500" data-aos-delay="10">            
            <v-img :src="require('./assets/title_logo.png')" width="481" height="auto" class="responsive-image"/>
            <!-- <span class="text-white">Flowy</span> -->
          </div>        
        </v-card>
        <!-- </v-container> -->
      <!-- </v-parallax> -->
      <v-form v-model="valid" class="py-16" :style="{ backgroundColor: currentPrimaryColor }">        
        <v-container style="max-width:1000px;" class="px-0" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-once="true" data-aos-duration="1000" data-aos-delay="10">
          <v-container>
            <v-sheet
              class="d-flex align-center justify-center flex-wrap text-center mx-auto px-4 py-8"
              elevation="4"
              height="100%"
              rounded
              max-width="800"
              width="100%"
            >
              <div>
                <h2 class="text-h3 font-weight-black text-light-blue">Flowy 이용 후기 이벤트</h2>

                <!-- <div class="text-h5 font-weight-medium mb-2">                
                </div> -->

                <p class="text-body-1 font-weight-bold mt-8">
                  이벤트 신청자 모두에게 Flowy 1개월 무료 제공!
                </p>
                <p class="text-body-1 font-weight-bold my-2 mb-4">
                  이용 후기까지 작성하면 <span class="font-weight-black text-light-blue">1등 냉장고</span>가 걸린 대박 경품까지!!
                </p>
              </div>
            </v-sheet>          
          </v-container>              
          <v-container>
            <transition name="slide-fade">
              <v-container v-if="!submitted">
                <v-sheet              
                  class="d-flex align-center justify-left flex-wrap text-left mt-16 mb-8 mx-auto px-4 py-4"
                  elevation="1"            
                  rounded            
                  width="100%"
                  height="100%"
                >
                <h2 class="text-h4 font-weight-black text-light-blue">STEP_01 &nbsp;&nbsp;<span class="font-weight-medium">약관 동의</span></h2>
                </v-sheet>          
                <v-row>
                  <v-col cols="12">
                  <v-card class="overflow-auto" style="min-height:30vh; max-height:50vh; height:auto">
                    <v-card-text>
                      <h3>약관</h3>                  
                    </v-card-text>
                  </v-card>
                </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-radio-group inline v-model="ex1" @update:model-value="changeAgree">
                      <v-radio aria-label="동의하지 않음" label="동의하지 않음" value="1" color="red" true-icon="mdi-close-circle" false-icon="mdi-circle"></v-radio>                
                      <v-radio aria-label="동의함" label="동의함" value="2" color="light-blue" true-icon="mdi-checkbox-marked-circle" false-icon="mdi-circle"></v-radio>                  
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </transition>                
          </v-container>          
          <v-container>
            <transition name="slide-fade">
              <v-container v-if="!submitted">
                <v-sheet            
                  class="d-flex align-center justify-left flex-wrap text-left mt-16 mb-8 mx-auto px-4 py-4"
                  elevation="1"            
                  rounded            
                  width="100%"
                  height="100%"
                >
                  <h2 class="text-h4 font-weight-black text-light-blue">STEP_02 &nbsp;&nbsp;<span class="font-weight-medium">신청서 제출</span></h2>
                </v-sheet>
                <v-row class="pt-4">
                  <v-col
                  cols="auto"
                  class="flex-grow"
                  >
                    <v-text-field                                    
                      density="compact"
                      v-model="email"                  
                      :rules="emailRules"
                      @input="emailInput"
                      :counter="255"
                      maxlength="255"
                      label="이메일"
                      placeholder="이메일"
                      variant="solo-filled"            
                      prepend-inner-icon="mdi-email-outline"                              
                      required                  
                    ></v-text-field>
                  </v-col>        
                  <v-col cols="auto">
                      <v-btn                  
                        :loading="sending"
                        size="large"
                        elevation="4"
                        variant="tonal"
                        @click="send"
                        :disabled="((validFlag&0x4)==0x4)||((validFlag&0x2)!=0x2)">
                        <span v-if="sent">재전송</span>
                        <span v-else>인증코드 전송</span>                    
                        <template v-slot:loader>
                          <v-progress-circular indeterminate></v-progress-circular>
                        </template>
                      </v-btn>                
                  </v-col>
                </v-row>          
                <v-row v-if="sent" class="d-flex align-center pb-2">
                  <v-col
                  cols="auto"
                  >
                    <v-text-field                    
                      class="vw6"
                      density="compact"
                      v-model="verifyCode"                  
                      :rules="codeRules"                  
                      label="인증코드"
                      placeholder="인증코드"
                      variant="solo-filled"            
                      maxlength="6"
                      :disabled="!remained||!sent||((validFlag&0x4)==0x4)"               
                      required                  
                      hide-details
                    ></v-text-field>
                  </v-col>        
                  <v-col cols="auto">
                    <v-btn        
                    id="veriBtn"
                    opac="1"
                    :loading="checking"
                    size="large"
                    elevation="4"
                    :variant="(validFlag&0x4)!=0x4?'tonal':'text'"
                    :prepend-icon="(reqVeri&&((validFlag&0x4)!=0x4))?'mdi-close-circle':'mdi-check-circle'"                
                    class="custom-disabled"
                    :disabled="!remained||!verifyCode||!sent||((validFlag&0x4)==0x4)"                                                
                    @click="verify">
                      <template v-slot:prepend>
                        <v-icon :color="reqVeri?(((validFlag&0x4)==0x4)?'success':'red'):'warning'"></v-icon>
                      </template>
                      <span>인증코드 확인</span>

                      </v-btn>
                      <template v-slot:loader>
                        <v-progress-circular indeterminate></v-progress-circular>
                      </template>
                  </v-col>
                  <v-col v-if="((validFlag&0x4)!=0x4)" cols="auto" id="timer">
                    <span></span>
                  </v-col>
                </v-row>          
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      density="compact"              
                      v-model="pwd"                
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="pwdRules"
                      :type="show1 ? 'text' : 'password'"            
                      @click:append="show1 = !show1"
                      :counter="255"
                      maxlength="255"
                      label="비밀번호"
                      placeholder=""               
                      variant="solo-filled"            
                      :disabled="exist"
                      prepend-inner-icon="mdi-form-textbox-password"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      density="compact"
                      v-model="fullname"
                      :rules="nameRules"
                      :counter="100"
                      label="이름"
                      maxlength="100"
                      placeholder="홍길동"               
                      variant="solo-filled"            
                      prepend-inner-icon="mdi-account-outline"
                      required            
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                  <v-text-field
                    density="compact"
                    v-model="phone"
                    :counter="13"
                    :rules="phoneRules"
                    label="휴대전화"
                    placeholder="010-####-####"
                    variant="solo-filled"            
                    prepend-inner-icon="mdi-cellphone"
                    required            
                    maxlength="13"
                    @input="autoHyphenPhone"
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                <v-spacer></v-spacer>
                </v-row>
                <v-row justify="space-evenly">
                  <v-col cols="auto">
                    <v-btn size="large" elevation="4" prepend-icon="mdi-magnify" variant="tonal" @click="showApi">
                      주소 검색
                    </v-btn>
                  </v-col>
                  <v-col            
                  :cols="auto"
                  >
                    <v-text-field   
                      class="vw6 centered-input"
                      density="compact"
                      :rules="zipRules"
                      v-model="addr.zip"
                      placeholder="우편번호"
                      variant="solo-filled"                                            
                      maxlength="5"
                      readonly
                      required
                    ></v-text-field>
                  </v-col>                    
                  <v-col            
                  :cols="12"
                  >
                    <v-text-field                   
                      density="compact"
                      v-model="addr.addr1"                                
                      :rules="addr1Rules"
                      placeholder="기본 주소"
                      variant="solo-filled"
                      readonly
                      required                                                                       
                    ></v-text-field>
                  </v-col>                    
                  <v-col            
                  :cols="12"
                  >
                    <v-text-field                   
                      density="compact"
                      v-model="addr.addr2"
                      :rules="addr2Rules"
                      placeholder="상세 주소"
                      variant="solo-filled"                
                      required                                                                       
                    ></v-text-field>
                  </v-col>                                
                </v-row>      
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="auto">
                    <v-btn                                       
                      class="me-4"                  
                      :color="(!isDisabled)?'light-blue':''"                                    
                      :disabled="isDisabled"
                      @click="submitAction"
                    >
                      제출하기
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>                           
              </v-container>
            </transition>
          </v-container>                    
          <v-container>
            <div ref="section4"></div>       
            <v-sheet                        
              class="d-flex align-center justify-left flex-wrap text-left mt-16 mb-8 mx-auto px-4 py-4"
              elevation="1"
              rounded
              width="100%"
              height="100%"
            >
              <div>
                <h2 class="text-h4 font-weight-black text-light-blue">STEP_03 &nbsp;&nbsp;<span class="font-weight-medium">Flowy 설치</span></h2>
                <p class="text-body-2 mt-4 mb-6">
                  Windows용 Flowy를 다운로드 후 설치하세요.
                </p>          
                <v-btn :href="'https://wapi.flowy.kr/resource/download/Flowy_Setup.exe'" target="_blank" ariant="text" color="light-blue" class="tonal mb-4">Flowy 다운로드</v-btn>            
              </div>
            </v-sheet>
          </v-container>          
          <v-container>
            <v-sheet
              class="d-flex align-center justify-left flex-wrap text-left mt-4 mb-8 mx-auto px-4 py-4"
              elevation="1"            
              rounded            
              width="100%"
              height="100%"
            >
              <div>
                <h2 class="text-h4 font-weight-black text-light-blue">STEP_04 &nbsp;&nbsp;<span class="font-weight-medium">이용 후기 작성</span></h2>
                <p class="text-body-2 mt-4 mb-2">
                  신청서에 입력했던 이메일과 비밀번호로 로그인하여 Flowy를 사용해보세요.
                </p>
                <p class="text-body-2 mt-0 mb-2">
                  이벤트 마감일인 <span class="font-weight-black text-light-blue">9월 30일</span>에 후기 작성이 가능한 URL을 신청서에 입력한 휴대전화로 전송해드립니다.
                </p>
                <p class="text-body-2 mt-0 mb-4">
                  Flowy를 충분히 사용해보시고 솔직한 후기를 남겨주시면 경품 추첨 대상자의 자격을 얻으실 수 있습니다.
                </p>
                <p class="text-body-2 mt-8 mb-4">
                  * 경품 추첨 관련 일정은 추후 후기 작성 페이지를 통해 공지 예정
                </p>
              </div>
            </v-sheet>
          </v-container>
        </v-container>
      </v-form>
    </v-main>
    <v-footer
      class="text-center d-flex flex-column"
      >
      <div>
        <v-btn
          v-for="item in icons"
          :key="item"
          class="mx-4"          
          :aria-label="item.label"                              
          :icon="item.icon"
          :href="item.url"          
          variant="text"          
          target="_blank"
        >        
        </v-btn>
      </div>
      <div class="pt-2">        
        <span class="mx-4 d-inline-flex">대표자 : 김상언</span>                
        <span class="mx-4 d-inline-flex">개인정보관리책임자 : 김상언</span>        
      </div>
      <div class="pt-0">
        우)04793, 서울특별시 성동구 성수일로12길20, 성동안심상가 801호
      </div>
      <div class="pt-0 pb-8">
        Copyright ⓒ {{ new Date().getFullYear() }} (주)오버플로우 All rights reserved.
      </div>
    </v-footer>
  </v-app>  
</template>

<style>
:root {
  --opac: '1.0';  
}
.responsive-image {  
  max-width:481;
  width: auto;
  height: auto;
}
.custom-disabled.v-btn--disabled[opac="1"] {      
  /* opacity:var(--opac) !important; */    
  opacity:1.0;
}
.centered-input input {  
  text-align: center;
}
@media (max-width: 561px) {
  .responsive-image {    
    width: auto;
    height: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.first-page {
  position: fixed; /* 배경 이미지를 고정시킵니다. */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('./assets/flowy.jpg'); /* 이미지 파일 경로를 지정합니다. */
  background-size: cover; /* 이미지를 컨테이너에 맞게 조정합니다. */
  background-repeat: no-repeat; /* 이미지 반복을 제어합니다. */
  background-position: center center;
  min-height: 100vh; /* 전체 높이를 화면에 맞게 설정합니다. */
  z-index: -1; /* 배경 이미지가 페이지 내용 뒤로 감추기 위해 z-index를 설정합니다. */
}
.background-container {
  /* 투명한 배경을 위한 스타일 설정 */
  position: relative;
  min-height: 100vh;
  /* 이 외에 필요한 스타일을 추가할 수 있습니다. */
}
.flex-grow {
  flex-grow: 1;
}
.vw6 {
  width:6rem;  
}
.vw5 {
  width:5rem;  
}
.slide-fade-leave-active {
  /* cubic-bezier(1.0, 0.5, 0.8, 1.0) */
  transition: all .8s;
  opacity: 0.5;
}
.slide-fade-leave-to {
  /* transform: translateX(10px); */
  opacity: 0;
}
</style>

<script>
//import vuetify from './plugins/vuetify';
import { useTheme } from 'vuetify'
import CryptoJS from "crypto-js";
import axios from 'axios';

export default {        
  name: 'App',
  created: function () {    
    document.title = 'Flowy 이벤트';
    // console.log('api key : '+thi s.apiKey);
    if (window.matchMedia) {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const theme = useTheme()  
      
      //브라우저 설정 변경 시 다크 모드 업데이트
      darkModeMediaQuery.addEventListener('change', function(e){
        //vue.theme.current.dark = true;
        //theme.current.dark = e.matches;        
        // console.log(e.matches);
        theme.global.name.value = e.matches ? 'dark' : 'light';
        //ss.theme.dark = e.matches;
      });
    }

    this.getToken();
    
    // match system color
    //this.$vuetify.theme.current.dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  },
  computed: {
    apiKey() {
      return CryptoJS.SHA256('ytufvfytyfytuff6f7g8ytg76t65df6fgkjghg6d5sd6tf7gtyrf56rf564').toString();
    },
    currentPrimaryColor() {
      console.log(this.$vuetify.theme.current);
      return this.$vuetify.theme.current.dark ? this.$vuetify.theme.themes.dark.colors.background : this.$vuetify.theme.themes.light.colors.background;
    },
    isDisabled() {
      if (this.exist) return ((this.validFlag&0x737)!=0x737);
      else return ((this.validFlag&0x73f)!=0x73f);
    },
    emailRules() {
      return [
      value => {
        if (value) {
          this.validFlag|=0x2;
          return true;
        }

        this.validFlag&=~0x2;
        return '이메일을 입력하세요.'
      },
      value => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
          this.validFlag|=0x2;
          return true;
        }

        this.validFlag&=~0x2;
        return '이메일 형식이 올바르지 않습니다.'
      },
    ] },
    codeRules() {
      return [
      value => {
        // let root = document.documentElement;        
        if (value) {          
          return true;
        }

        this.validFlag&=~0x4;
        let btn = document.getElementById("veriBtn");        
        btn.setAttribute("opac", "");                        
        // root.style.setProperty('--opac', 0.5);        
        return '인증코드를 입력하세요.'
      },      
    ] },
    pwdRules() {
      return [
      value => {        
        if (value.length>0) {
          this.validFlag|=0x8;
          return true;          
        }

        this.validFlag&=~0x8;
        return '비밀번호를 입력하세요.';
      },
      v => {
        if (v.length >= 8) {
          this.validFlag|=0x8;
          return true;
        }

        this.validFlag&=~0x8;
        return '비밀번호는 최소 8글자 이상이어야 합니다.';
      },
    ] },
    nameRules() {
      return [
      value => {
        if (value) {
          this.validFlag|=0x10;
          return true
        }

        this.validFlag&=~0x10;
        return '이름을 입력하세요.'
      },
      value => {
        if (/^[가-힣]+$/.test(value) && value?.length >= 2 && value?.length <= 100) {
          this.validFlag|=0x10;
          return true
        }
        else {
          this.validFlag&=~0x10;
          
          if (/^[가-힣]+$/.test(value) === false)
            return '이름은 한글로만 입력할 수 있습니다.'
          else {              
              if (value?.length < 2)
                return '이름은 2글자 이상이어야 합니다.'
              else
                return '이름은 100글자 이하여야 합니다.'
          }
        }          
      },
    ] },
    phoneRules() {
      return [
      v => {
        if (v.length>0) {
          this.validFlag|=0x20;
          return true;
        }

        this.validFlag&=~0x20;
        return '휴대전화는 필수 항목입니다.';
      },
      v => {
        if (v.length <= 13) {
          this.validFlag|=0x20;          
          return true;
        }

        this.validFlag&=~0x20;
        return '휴대전화가 형식에 맞지 않습니다.';
      },
      v => {
        if (/^010-\d{4}-\d{4}$/.test(v)) {
          this.validFlag|=0x20;          
          return true;
        }
        
        this.validFlag&=~0x20;
        return '휴대전화가 형식에 맞지 않습니다.'
      },
    ] },
    zipRules() {
      return [
      v => {
        if (v.length>0) {
          this.validFlag|=0x100;
          return true;
        }

        this.validFlag&=~0x100;
        return '우편번호는 필수 항목입니다.';
      },      
    ] },
    addr1Rules() {
      return [
      v => {
        if (v.length>0) {
          this.validFlag|=0x200;
          return true;
        }

        this.validFlag&=~0x200;
        return '기본 주소는 필수 항목입니다.';
      },      
    ] },
    addr2Rules() {
      return [
      v => {
        if (v.length>0) {
          this.validFlag|=0x400;
          return true;
        }

        this.validFlag&=~0x400;
        return '상세 주소는 필수 항목입니다.';
      },      
    ] },
  },
  components: {
  },        
  data: () => ({
    BASEURL:'https://e01.flowy.kr/',
    timer:null,
    remain:900,
    validFlag:0x0,
    flags:{
      agreement:0x1,
      email:0x2,
      verify:0x4,
      password:0x8,
      name:0x10,
      phone:0x20,
      zipcode:0x100,
      address1:0x200,
      address2:0x400,      
    },
    ex1:'1',
    sending:false,    
    sent:false,
    remained:true,
    emailSent:'',    
    reqVeri:false,
    checking:false,
    verifiedEmail:'',
    submitted:false,
    exist:false,
    addr: 
        {
          zip: '',
          addr1: '',
          addr2: '',
        },
    icons: [        
      {
        icon:'mdi-youtube',
        label:'Youtube',
        url:'https://www.youtube.com/channel/UCUEMdzteMSzfulmgVAO7Kcg?view_as=subscriber'
      },
      {
        icon:'mdi-facebook',      
        label:'Facebook',  
        url:'https://www.facebook.com/AToverflow/'
      },
      {
        icon:'mdi-instagram',
        label:'Instagram',
        url:'https://www.instagram.com/atoverflow/?hl=ko'
      },
      {
        icon:'mdi-blogger',
        label:'Blog',
        url:'https://blog.naver.com/overflowbiz'
      },
      
      ],    
    token:'',
    timeout:'',
    valid: false,
    fullname: '',
    pwd: '',
    show1: false,
    show2: true,
    email: '',
    verifyCode: '',
    phone: '',
    phoneInput: '',
  }),
  watch: {
    
  },
  methods: {                
    changeAgree() {
      if (this.ex1==='2') {
        this.validFlag|=0x1;        
      }
      else
        this.validFlag&=~0x1;
    },
    call(f, v) {      
      if (!v) v = this;      

      if (Date.parse(v.timeout)>Date.now()) {
        // console.log('timeout '+Date.parse(v.timeout));
        // console.log('now '+Date.now());
        f(error => {        
          if (error.response) {
            if (error.response.status===401) {          
              console.log(error.response.status)          
              v.getToken(f);
            } else {
              // const errmsg = `오류가 발생했습니다. ${error.response.status}';
              alert(`오류가 발생했습니다. (${error.response.status})`);              
            }        
          } else {
            alert('서버와 통신할 수 없습니다.');
          }
        });
      } else {
        v.getToken(f);
      }
    },    
    getToken(f, v) {    
      if (!v) v = this;
      
      console.log('getToken');
      axios.post(v.BASEURL+'authenticate', {}, {
        headers: {
          'X-API-Key':this.apiKey,
          'Authorization':this.token
        },timeout:15000
      }).then(res => {        
        // console.log(res.data);
        if (res.data.result===true && res.data.result_code===0) {
          v.token = res.data.token_id;
          v.timeout = res.data.timeout;

          if (f) f();
        }        
      }).catch((error) => {
        console.log(error);
      });
      // axios.post('http://e01t.flowy.kr:41001/authenticate', {}, {
      // const res = await axios.post('http://e01t.flowy.kr:41001/authenticate', {}, {
      //   headers: {
      //     'X-API-Key':this.apiKey,
      //     'Authorization':this.token
      //   }
      // });
      
      // console.log(res.data);              
      // this.token = res.data.token_id;
    },    
    send() {                  
      const v = this;

      this.call(function(err) {        
        console.log('join');
        v.sending = true;            
        const sendData = {
          'send_auth_code':true,
          'email':v.email,
        };
        axios.post(v.BASEURL+'join', sendData, {
          headers: {
            'X-API-Key':v.apiKey,
            'Authorization':v.token
          },timeout:5000
        }).then(res => {        
          if (res.data.result===true) {
            if (res.data.result_code===2)
              v.exist = true;
            else
              v.exist = false;

            v.sent = true;        
            v.validFlag&=~0x4;
            v.reqVeri = false;
            v.emailSent = v.email;            
            v.remain = 900;
            v.remained = true;
            if (v.timer) {
              clearInterval(v.timer);              
            }
            v.timer = setInterval(v.updateTimer, 1000, v);
          } else {
            alert('이벤트 참여 가능한 이메일이 아닙니다.');
          }
          // console.log(res.data);            
        }).catch((error) => {
          if (err) err(error);                    
        }).finally(() => {
          v.sending  = false;                                
        });
      });      
    },
    updateTimer(v) {
      if (!v) v = this;
      let disp = document.getElementById("timer");
      if (disp) {
        if (this.remain<=0) {
          disp.innerText = `시간 초과`;
          if (v.timer) {
            clearInterval(v.timer);
          }

          v.verifyCode = '';          
          v.remained = false;
        } else {
          const minutes = Math.floor(v.remain/60);       
          const seconds = v.remain % 60;
          
          disp.innerText = `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
          v.remain--;
        }
      }
    },
    verify() {
      this.reqVeri = true;
      this.checking = true;
      const v = this;      
            
      this.call(function(err) {        
        console.log('verify');        
        const sendData = {          
          'email':v.email,
          'auth_code':v.verifyCode,
        };
        axios.post(v.BASEURL+'confirmAuthCode', sendData, {
          headers: {
            'X-API-Key':v.apiKey,
            'Authorization':v.token
          },timeout:15000
        }).then(res => {        
          if (res.data.result===true && res.data.result_code===0) {
            if (v.timer) {
              clearInterval(v.timer);          
            }            
            
            v.validFlag|=0x4;                
            let btn = document.getElementById("veriBtn");                
            btn.setAttribute("opac", "1");                      
          } else {
            if (res.data.result==true) {
              if (res.data.result_code===1)
                alert('시간이 초과되었습니다.');
              else if (res.data.result_code===2)
                alert('인증코드가 올바르지 않습니다.');
              else if (res.data.result_code===3)
                alert('인증을 요청한 이메일 정보와 일치하지 않습니다.');
            } else
              alert('인증 실패');
          }
          // console.log(res.data);            
        }).catch((error) => {
          if (err) err(error);                    
        }).finally(() => {
          v.checking = false;                                     
        });
      });
    },    
    submitAction() {
      const v = this;

      this.call(function(err) {        
        console.log('submit');
        // v.sending = true;            
        const fulladdr = ''+v.addr.zip+' '+v.addr.addr1+' '+v.addr.addr2;
        const sendData = {
          'send_auth_code':false,
          'email':v.email,
          'password':v.pwd,
          'name':v.fullname,
          'phone':v.phone,
          'address':fulladdr.substring(0, 100),
          'agreement':(v.ex1==='2')?true:false,
        };
        // console.log(sendData);
        axios.post(v.BASEURL+'join', sendData, {
          headers: {
            'X-API-Key':v.apiKey,
            'Authorization':v.token
          },timeout:15000,
        }).then(res => {        
          if (res.data.result===true && res.data.result_code===0) {
            alert('정상적으로 처리되었습니다.');
            v.$refs['section4'].scrollIntoView({behavior: "smooth", block:"start"});            
            v.submitted = true;
          } else {
            alert('이벤트 참여 가능한 이메일이 아닙니다.');
          }
          // console.log(res.data);            
        }).catch((error) => {
          if (err) err(error);                    
        }).finally(() => {          
        });
      });      
    },
    showApi() {
        new window.daum.Postcode({ 
            oncomplete: (data) => {
                let fullRoadAddr = data.roadAddress; // 도로명 주소 변수 
                let extraRoadAddr = ''; // 도로명 조합형 주소 변수

                if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                    extraRoadAddr += data.bname; 
                }

                if(data.buildingName !== '' && data.apartment === 'Y'){ 
                    extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName); 
                } // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다. 

                if(extraRoadAddr !== ''){ 
                    extraRoadAddr = ' (' + extraRoadAddr + ')'; 
                } // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다. 

                if(fullRoadAddr !== ''){ 
                    fullRoadAddr += extraRoadAddr; 
                } // 우편번호와 주소 정보를 해당 필드에 넣는다. 

                this.addr.zip = data.zonecode; //5자리 새우편번호 사용
                this.addr.addr1 = fullRoadAddr;
                
                
                //this.$refs['addr2-modal'].show() //상세 주소 입력 모달 창 보이기
            }
        }).open()
    },
    emailInput: function(e) {
      // console.log(e.target.value+' / '+this.emailSent);
      if (this.sent && this.emailSent!==e.target.value) {        
        this.validFlag&=~0x4;
        this.verifyCode = '';
        this.reqVeri = false;
        this.sent = false;        
        this.emailSent = '';
        this.exist = false;
      }
    },
    autoHyphenPhone: function(e) {      
      //console.log(''+e.key);
      //if (e.key >= 0 && e.key <= 9)
      {        
        setTimeout(() => {                    
          const input = e.target;
          let start = input.selectionStart;
          let end = input.selectionEnd;

          const value = this.phone;          
          const remove1 = value.replace(/\D/g, '');
          const remove2 = this.phoneInput.replace(/\D/g, '');

          let newValue = remove1;
          if (remove1.length > 3 && remove1.length <= 7) {
            newValue = remove1.slice(0, 3) + '-' + remove1.slice(3);
            //console.log('case 1');
          } else if (remove1.length > 7) {
            newValue = remove1.slice(0, 3) + '-' + remove1.slice(3, 7) + '-' + remove1.slice(7);
            //console.log('case 2');
          }
          if (remove1!==remove2) {
            if (value.length < newValue.length) {
              start+=(newValue.length-value.length);
              end+=(newValue.length-value.length);
              //console.log('case 3');
            } else if (value.length > newValue.length) {
              start-=(value.length-newValue.length);
              end-=(value.length-newValue.length);
              //console.log('case 4');
            }        
          }            
          
          this.phone = newValue.slice(0,13);            
          this.phoneInput = this.phone;
          this.$nextTick(() => {
            input.setSelectionRange(start, end);
            //console.log(''+start+' / '+end+'');
          });          
        }, 0);
      }
    }
  },  
}
</script>