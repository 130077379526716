// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
//import { useTheme } from 'vuetify'

export default createVuetify(
  {
    theme: {
      defaultTheme: (window.matchMedia("(prefers-color-scheme: dark)").matches)?'dark':'light',
    }
  }
)