import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//import { createVuetify } from 'vuetify';
//import 'vuetify/dist/vuetify.css'; // 이 부분은 Vuetify CSS를 임포트하는 것으로 필요에 따라 변경될 수 있습니다.
import { loadFonts } from './plugins/webfontloader'
import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'

loadFonts()
//const vuetify = createVuetify();

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(vuetify);
app.use(AOS.init());
app.mount('#app');